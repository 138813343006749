import React, { useEffect } from "react"
import { Avatar } from "@planningcenter/components"
import { compact, flatten, isArray, isEmpty, isObject, pickBy } from "lodash"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import api from "utils/church_center_api"
import log from "utils/log"
import LaddaButton, { stopLaddaButton } from "components/ladda_button"
import Nl2Br from "components/nl2br"
import { Loading } from "components/church_center/loading"
import { ErrorMessages, Field, TextInput } from "components/church_center/ui"
import RecaptchaForm from "components/recaptcha_form"
import {
  AddressField,
  CheckboxesField,
  DateField,
  DropdownField,
  FileInputField,
  GenderField,
  HeadingField,
  HouseholdField,
  NumberField,
  PhoneNumberField,
  TextAreaField,
  TextInputField,
  WorkflowCheckboxField,
  WorkflowCheckboxesField,
  WorkflowDropdownField,
  YesNoField,
} from "components/church_center/field_components"
import Icon from "components/church_center/cc_external_icon"
import { OTHER_OPTION_ID } from "components/forms"
import sanitizeHtml from "sanitize-html"
import { debounce } from "lodash"

const TOTAL_FILE_SIZE_LIMIT = 1024 * 1024 * 10 * 5

const isEmptyish = value => {
  if (!value) return true
  if (isObject(value)) return Object.values(value).every(v => isEmptyish(v))
  if (isArray(value)) return value.every(v => isEmptyish(v))
  return false
}

const fieldIsCustomField = ({ attributes }) =>
  attributes.field_type && attributes.field_type.match(/^custom_/)

export default function RecaptchaWrappedForm(props) {
  return (
    <RecaptchaForm {...props}>
      <Form />
    </RecaptchaForm>
  )
}

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      attachments: [],
      countries: [],
      fields: [],
      included: [],
      values: {},
      errors: [],
      fieldErrors: [],
      loading: true,
      submitted: false,
      verificationRequired: false,
      submissionConfirmation: {},
      loggedInPerson: null,
      firstName: props.defaultFirstName || "",
      lastName: props.defaultLastName || "",
      emailAddress: props.defaultEmailAddress || "",
      person: null,
    }

    this.headerRef = React.createRef()
    this.componentForType = this.componentForType.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.handleMergedValueChange = this.handleMergedValueChange.bind(this)
    this.handleAddAttachment = this.handleAddAttachment.bind(this)
    this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this)
    this.prepopulateValues = this.prepopulateValues.bind(this)
    this.genderOptions = this.genderOptions.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.handleEmailSuggestionClick = this.handleEmailSuggestionClick.bind(this)
  }

  componentDidMount() {
    const { id, person } = this.props
    let allFields = []

    api.getAll(
      `/people/v2/forms/${id}/fields`,
      {
        order: "sequence",
        include:
          "options,field_definitions,field_options,marital_statuses,campuses,form_field_conditions,school_options,genders",
        per_page: "100",
      },
      resp => {
        this.setState(({ fields, included, values }) => {
          allFields = fields.concat(resp.data)

          return {
            fields: allFields,
            included: included.concat(resp.included),
            values: { ...values, ...this.prepopulateValues(resp.data) },
          }
        })
      },
      () => {
        const hasAddressField = allFields.some(
          field => field.attributes.field_type === "address"
        )

        if (hasAddressField) {
          api.get("/people/v2/countries", {}, countriesResp => {
            this.setState({ countries: countriesResp.data })
          })
        }

        if (person) {
          this.setState({ person, loading: false })
        } else {
          sessionApiClient
            .check()
            .then(browserSessionPerson => {
              if (
                browserSessionPerson.data.type === "VerifiedInSessionPerson"
              ) {
                this.setState({
                  loading: false,
                  loggedInPerson: browserSessionPerson,
                })
              } else {
                this.setState({ loading: false })
              }
            })
            .catch(() => this.setState({ loading: false }))
        }
      }
    )
  }

  render() {
    const {
      fields,
      loading,
      errors,
      submitted,
      verificationRequired,
      submissionConfirmation,
      loggedInPerson,
      firstName,
      lastName,
      emailAddress,
      person,
      suggestedEmailAddress,
    } = this.state

    const {
      name,
      description,
      sendSubmissionNotificationToSubmitter,
      wrapSubmit,
    } = this.props
    const authErrors = errors.authErrors || {}
    const showTypoSuggestion =
      suggestedEmailAddress && emailAddress !== suggestedEmailAddress

    return loading ? (
      <div ref={this.headerRef}>
        <div className="mb-1">
          <Header name={name} description={description} />
        </div>
        <Loading />
      </div>
    ) : submitted ? (
      <Thanks
        attachments={this.state.attachments}
        verificationRequired={verificationRequired}
        sendSubmissionNotificationToSubmitter={
          sendSubmissionNotificationToSubmitter
        }
        submissionConfirmation={submissionConfirmation}
        onClick={() =>
          this.setState({
            values: this.prepopulateValues(fields),
            errors: [],
            fieldErrors: [],
            submitted: false,
            submissionConfirmation: {},
            verificationRequired: false,
            loading: false,
            firstName: "",
            lastName: "",
            emailAddress: "",
          })
        }
      />
    ) : (
      <div ref={this.headerRef}>
        <Header name={name} description={description}>
          {(!isEmpty(errors.fieldErrors) || !isEmpty(errors.authErrors)) && (
            <div className="my-2">
              <div className="quiet-alert error-alert alert d-f ai-c mb-2">
                <span className="icon c-ruby mr-1 fs-3">
                  <Icon symbol="general#exclamation-triangle" aria-hidden />
                </span>
                <strong className="ta-l c-ruby">
                  Uh oh! Looks like there were some problems.
                </strong>
              </div>
            </div>
          )}
        </Header>
        <form onSubmit={e => e.preventDefault()}>
          <section>
            <div>
              {loggedInPerson ? (
                <NameBanner person={loggedInPerson} info="My information" />
              ) : person ? (
                <NameBanner person={person} info="Submitting form for" />
              ) : (
                <div id="new-person-form">
                  <Field
                    id="1"
                    field_type="your_name"
                    label="Your name"
                    required
                  >
                    <div className="d-f@md f_1 d-b@iframe">
                      <div>
                        <TextInput
                          errors={authErrors["first name"]}
                          id="your_name_1"
                          placeholder="First name"
                          autoComplete="given-name"
                          autoFocus="autofocus"
                          value={firstName}
                          spellCheck={false}
                          onChange={e =>
                            this.setState({ firstName: e.target.value })
                          }
                          required
                        />
                        <ErrorMessages
                          errors={authErrors["first name"]}
                          className="mt-1"
                        />
                        {this.showFirstNameWarning(firstName) && (
                          <div className="c-marigold mt-1">
                            For accuracy, use one name only.
                          </div>
                        )}
                      </div>
                      <div className="ml-0 ml-1@md ml-0@iframe mt-1 mt-0@md mt-1@iframe">
                        <label
                          className="screen-reader-text"
                          htmlFor="last_name"
                        >
                          Last name
                        </label>
                        <TextInput
                          id="last_name"
                          errors={authErrors["last name"]}
                          placeholder="Last name"
                          autoComplete="family-name"
                          value={lastName}
                          spellCheck={false}
                          onChange={e =>
                            this.setState({ lastName: e.target.value })
                          }
                          required
                        />
                        <ErrorMessages
                          errors={authErrors["last name"]}
                          className="mt-1"
                        />
                      </div>
                    </div>
                  </Field>
                  <Field
                    id="1"
                    errors={authErrors["email address"]}
                    field_type="email_address"
                    label="Email address"
                    required
                  >
                    <TextInput
                      errors={authErrors["email address"]}
                      type="email"
                      id="email_address_1"
                      placeholder="name@example.com"
                      autoComplete="email"
                      value={emailAddress}
                      onChange={this.handleAddressChange}
                      required
                      {...(showTypoSuggestion && {
                        style: {
                          boxShadow: "none",
                          outline: "none",
                          borderColor: "var(--color-citrine)",
                        },
                      })}
                    />
                    {showTypoSuggestion && (
                      <div
                        className="fs-4 mt-2p"
                        style={{
                          color: "var(--warning-alert--text)",
                        }}
                      >
                        Did you mean <strong>{suggestedEmailAddress}</strong>?{" "}
                        <button
                          className="btn sm-btn text-btn fs-5 h-2 td-u:h"
                          onClick={this.handleEmailSuggestionClick}
                        >
                          Replace
                        </button>
                      </div>
                    )}
                  </Field>
                </div>
              )}

              {fields.map(f => this.componentForType(f))}

              <div className="my-4 ta-c">
                <LaddaButton
                  onClick={() =>
                    wrapSubmit(this.handleSubmit)({
                      id: loggedInPerson
                        ? loggedInPerson.data.id
                        : person && person.data.id,
                      firstName,
                      lastName,
                      emailAddress: loggedInPerson
                        ? loggedInPerson.data.attributes.email_address
                        : emailAddress,
                    })
                  }
                  spinnerColor="#999999"
                  className="btn"
                >
                  Submit
                </LaddaButton>
              </div>
            </div>
          </section>
          {errors.formErrors && (
            <p className="ta-c mt-1 c-ruby">{errors.formErrors}</p>
          )}
        </form>
      </div>
    )
  }

  handleAddressChange = e => {
    fetchAddressSuggestionDebounced(e.target.value, newState =>
      this.setState({
        suggestedEmailAddress: newState,
      })
    )

    this.setState({
      suggestedEmailAddress: undefined,
    })

    this.setState({ emailAddress: e.target.value })
  }

  handleEmailSuggestionClick() {
    this.setState({
      emailAddress: this.state.suggestedEmailAddress,
    })
    this.setState({
      suggestedEmailAddress: undefined,
    })
    trackEmailSuggestionClick(
      this.state.emailAddress,
      this.state.suggestedEmailAddress
    )
  }

  showFirstNameWarning(firstName) {
    return /(&|\sand\s|&amp;|\/|\\)/i.test(firstName)
  }

  totalFileSizeExceedsLimit = () => {
    return (
      this.state.attachments
        .flatMap(attachment => attachment.files)
        .reduce((acc, currentFile) => acc + currentFile.size, 0) >
      TOTAL_FILE_SIZE_LIMIT
    )
  }

  prepopulateValues = fields => {
    const prepopulatedValues = {}
    fields.forEach(field => {
      if (field.attributes.field_type === "address") {
        prepopulatedValues[field.id] = {
          country_code: this.props.defaultCountryCode,
        }
      }
    })

    return prepopulatedValues
  }

  handleAddAttachment = attachment => {
    if (attachment) {
      const newAttachments = this.state.attachments.filter(
        att => att.form_field_id !== attachment.form_field_id
      )
      this.setState({ attachments: [...newAttachments, attachment] })
      this.handleValueChange("attachments", attachment.form_field_id)
    }
  }

  handleRemoveAttachment = (formFieldId, fileName) => {
    const attachments = this.state.attachments.filter(
      at => at.form_field_id !== formFieldId
    )

    const filesForFormField = this.attachmentsForField(formFieldId).filter(
      att => att.name !== fileName
    )

    const newAttachments =
      filesForFormField.length > 0
        ? [
            ...attachments,
            { form_field_id: formFieldId, files: filesForFormField },
          ]
        : attachments

    this.setState({
      attachments: newAttachments,
    })

    if (filesForFormField.length === 0) {
      this.handleValueChange("", formFieldId)
    }
  }

  handleSubmit = async person => {
    const { id, encryptedParam, recaptchaToken } = this.props
    const { values, attachments, fields } = this.state
    const uploads = attachments.map(a => uploadFileAttachment(a))
    const uploadedAttachments = await Promise.all(uploads)

    const included = buildIncludedParam(
      trimmedValues(values),
      uploadedAttachments,
      fields
    )

    let attributes
    if (person.id) {
      attributes = {
        person_id: person.id,
        email_address: person.emailAddress,
        _recaptcha_token: recaptchaToken,
      }
      if (encryptedParam) {
        attributes["_e"] = encryptedParam
      }
    } else {
      attributes = {
        _recaptcha_token: recaptchaToken,
        email_address: person.emailAddress,
        person_attributes: {
          first_name: person.firstName,
          last_name: person.lastName,
          emails_attributes: [
            { location: "Home", address: person.emailAddress },
          ],
        },
      }
    }

    api.post(
      `/people/v2/forms/${id}/form_submissions`,
      {
        data: {
          type: "FormSubmission",
          attributes,
        },
        included,
      },
      resp => {
        if (resp.errors) {
          const formSubmissionErrors = resp.errors.find(
            e => e.source && e.source.parameter === "form_submission_values"
          )

          const errors = {
            authErrors: extractAuthErrors(resp.errors),
            fieldErrors: extractFieldErrors(formSubmissionErrors),
          }

          if (
            !errors.fieldErrors &&
            (!errors.authErrors || isEmpty(errors.authErrors))
          ) {
            errors.formErrors = resp.errors[0].detail
          }

          if (!isEmpty(errors.authErrors)) {
            this.setState(
              { errors, person: null, loggedInPerson: null },
              stopLaddaButton
            )
          } else {
            this.setState({ errors }, stopLaddaButton)
          }

          if (this.headerRef.current) {
            setTimeout(() =>
              this.headerRef.current.scrollIntoView({ behavior: "smooth" })
            )
          }
        } else {
          this.setState({
            submitted: true,
            verificationRequired: resp.meta.requires_verification,
            submissionConfirmation: {
              basicInformation: resp.meta.basic_information,
              groupedSubmissionValues: resp.meta.grouped_submission_values,
            },
          })
        }
      }
    )
  }

  handleLogout = () => {
    sessionApiClient.logout().then(() => Turbolinks.visit())
  }

  fieldHasConditionNotMet = (field, fields) => {
    const { included, values } = this.state

    const condition = included.find(
      i =>
        i.type === "FormFieldCondition" &&
        i.relationships.form_field.data.id === field.id
    )

    if (!condition) return false

    const conditionTriggerId =
      condition.relationships.trigger_form_field.data.id
    const conditionOptionTrigger =
      condition?.relationships?.trigger_form_field_option?.data
    const conditionOptionValueTrigger =
      condition?.attributes?.trigger_form_field_option_value
    const conditionSelected = !isEmptyish(values[conditionTriggerId])

    const conditionTrigger = fields.find(f => f.id === conditionTriggerId)

    if (conditionOptionTrigger || conditionOptionValueTrigger) {
      // this trigger relies on an option of a form field

      // check for whether this is the "other" option for a dropdown,
      // which is not allowed as an option trigger
      if (
        values[conditionTriggerId] &&
        !values[conditionTriggerId]?.selected &&
        valueIsBlankDropdownOtherOption(values[conditionTriggerId])
      ) {
        return true
      }

      const conditionOptionSelected = triggerIsCheckboxes(
        values,
        conditionTriggerId
      )
        ? optionIsSelectedInCheckboxes({
            conditionOptionTrigger,
            conditionTrigger,
            conditionTriggerId,
            included,
            values,
          })
        : optionIsSelectedInDropdown(
            values[conditionTriggerId],
            conditionOptionTrigger,
            conditionOptionValueTrigger
          )

      return !(conditionSelected && conditionOptionSelected)
    } else {
      // this trigger just relies on the form field itself

      return !conditionSelected
    }
  }

  attachmentsForField(id) {
    const attachements = this.state.attachments.find(
      att => att.form_field_id === id
    )
    return attachements ? attachements["files"] : []
  }

  genderOptions(genders = []) {
    return genders.data.map(o => {
      const {
        id,
        attributes: { value },
      } = this.findOption(o)
      return (
        <option key={id} value={id}>
          {value}
        </option>
      )
    })
  }

  componentForType(field) {
    const { attributes, relationships, id } = field
    const { field_type, settings } = attributes
    const { phoneTypes, addressTypes, grades } = this.props
    const { errors, fields, values, countries } = this.state

    const relevantErrors = errors.fieldErrors?.[id]

    const hide = this.fieldHasConditionNotMet(field, fields)

    if (hide) return null

    switch (field_type) {
      case "string":
        return (
          <TextInputField
            id={id}
            key={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
          />
        )
      case "checkboxes":
        return (
          <CheckboxesField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={relationships.options.data.map(o => this.findOption(o))}
            otherOption={settings && settings.other}
          />
        )
      case "custom_checkboxes":
        return (
          <CheckboxesField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={relationships.field_options.data.map(o =>
              this.findOption(o)
            )}
          />
        )
      case "phone_number":
        return (
          <PhoneNumberField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleMergedValueChange}
            phoneTypes={phoneTypes}
          />
        )
      case "text":
      case "medical":
      case "note":
        return (
          <TextAreaField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
          />
        )
      case "dropdown": {
        const options = relationships.options.data
          .map(o => this.findOption(o))
          .sort((a, b) => a.attributes.sequence - b.attributes.sequence)
        return (
          <DropdownField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={options.map(({ attributes: { label }, id }) => (
              <option key={id} value={id}>
                {label}
              </option>
            ))}
            otherOption={settings && settings.other}
          />
        )
      }
      case "custom_dropdown":
        return (
          <DropdownField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={relationships.field_options.data.map(o => {
              const {
                attributes: { label },
                id,
              } = this.findOption(o)
              return (
                <option key={id} value={id}>
                  {label}
                </option>
              )
            })}
          />
        )
      case "address":
        return (
          <AddressField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            value={values[id]}
            countries={countries}
            locationOptions={addressTypes.map(at => (
              <option key={at}>{at}</option>
            ))}
            onChange={this.handleMergedValueChange}
          />
        )
      case "gender":
        return (
          <GenderField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={this.genderOptions(relationships.genders)}
          />
        )
      case "workflow_checkbox":
        return (
          <WorkflowCheckboxField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={relationships.options.data.map(o => this.findOption(o))}
          />
        )
      case "workflow_checkboxes":
        return (
          <WorkflowCheckboxesField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            onOtherChange={this.handleMergedValueChange}
            options={relationships.options.data.map(o => this.findOption(o))}
            otherOption={relationships.fieldable}
          />
        )
      case "workflow_dropdown":
        return (
          <WorkflowDropdownField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            onOtherChange={this.handleMergedValueChange}
            options={relationships.options.data.map(o => this.findOption(o))}
            otherOption={relationships.fieldable}
          />
        )
      case "custom_field": {
        const fieldDefinition = this.findOption({
          id: relationships.field_definitions.data.id,
          type: "FieldDefinition",
        })

        const {
          attributes: { data_type, config },
        } = fieldDefinition

        let field_type

        switch (data_type) {
          case "select":
            field_type = "custom_dropdown"
            break
          case "checkboxes":
            field_type = "custom_checkboxes"
            break
          case "boolean":
            field_type = "custom_boolean"
            break
          default:
            field_type = data_type
            break
        }

        const component = this.componentForType({
          attributes: {
            ...attributes,
            settings: { ...settings, ...config },
            field_type,
          },
          relationships,
          id,
          hide,
        })

        return component
      }
      case "birthday":
        return (
          <DateField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            autoComplete="bday"
            onChange={this.handleValueChange}
            yearRange={"c-120Y:c"}
            className="date-field__input"
          />
        )
      case "date":
      case "anniversary":
        return (
          <DateField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            yearRange={"-100:+20"}
            className="date-field__input"
          />
        )
      case "heading":
        return <HeadingField key={id} id={id} attributes={attributes} />
      case "boolean":
        return (
          <YesNoField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
          />
        )
      case "custom_boolean":
        return (
          <DropdownField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={[
              <option key="yes" value={true}>
                Yes
              </option>,
              <option key="no" value={false}>
                No
              </option>,
            ]}
          />
        )
      case "file": {
        const fileUploadErrors = relevantErrors
          ? { attachments: relevantErrors.value }
          : {}
        const multipleFiles = !!attributes.settings["multiple_files"]

        return (
          <FileInputField
            key={id}
            id={id}
            attributes={attributes}
            errors={fileUploadErrors}
            props={this.props}
            onAddFile={this.handleAddAttachment}
            onRemoveFile={this.handleRemoveAttachment}
            multiFileInput={multipleFiles}
            maxTotalFileSize={TOTAL_FILE_SIZE_LIMIT}
            totalFileSizeExceedsLimit={this.totalFileSizeExceedsLimit()}
          />
        )
      }
      case "number":
        return (
          <NumberField
            key={id}
            id={id}
            value={values[id]}
            attributes={attributes}
            errors={relevantErrors}
            onChange={this.handleValueChange}
          />
        )
      case "primary_campus":
        return (
          <DropdownField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={relationships.campuses.data.map(o => {
              const {
                attributes: { name },
                id,
              } = this.findOption(o)
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              )
            })}
          />
        )
      case "marital_status":
        return (
          <DropdownField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={relationships.marital_statuses.data.map(o => {
              const {
                attributes: { label },
                id,
              } = this.findOption(o)
              return (
                <option key={id} value={id}>
                  {label}
                </option>
              )
            })}
          />
        )
      case "school":
        return (
          <DropdownField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={relationships.school_options.data.map(o => {
              const {
                attributes: { label },
                id,
              } = this.findOption(o)
              return (
                <option key={id} value={id}>
                  {label}
                </option>
              )
            })}
          />
        )
      case "grade":
        return (
          <DropdownField
            key={id}
            id={id}
            errors={relevantErrors}
            attributes={attributes}
            onChange={this.handleValueChange}
            options={grades.map(({ key, value }) => {
              return (
                <option key={key} value={key}>
                  {value}
                </option>
              )
            })}
          />
        )
      case "household":
        return (
          <HouseholdField
            key={id}
            id={id}
            values={this.state.values[id]}
            errors={relevantErrors}
            onChange={this.handleValueChange}
            attributes={attributes}
            phoneTypes={phoneTypes}
            genderOptions={this.genderOptions(relationships.genders)}
            maritalStatusOptions={relationships.marital_statuses.data.map(o => {
              const {
                attributes: { label },
                id,
              } = this.findOption(o)
              return (
                <option key={id} value={id}>
                  {label}
                </option>
              )
            })}
            grades={grades}
            schoolOptions={relationships.school_options.data.map(o => {
              const {
                attributes: { label },
                id,
              } = this.findOption(o)
              return (
                <option key={id} value={id}>
                  {label}
                </option>
              )
            })}
          />
        )
      default:
        log.error("field_type isn't handled:", field_type)
        return null
    }
  }

  findOption({ id, type }) {
    const { included } = this.state
    return included.find(o => o.id === id && o.type === type)
  }

  handleValueChange(value, id) {
    const { values } = this.state
    let newValues = Object.assign(values, { [id]: value })

    if (isEmpty(newValues[id]) && newValues[id] !== true) {
      delete newValues[id]
    }

    this.setState({ values: newValues })
  }

  handleMergedValueChange(obj, id) {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [id]: { ...prevState.values[id], ...obj },
      },
    }))
  }
}

const fetchAddressSuggestionDebounced = debounce(
  (address, callback) => fetchAddressSuggestion(address, callback),
  300
)

const fetchAddressSuggestion = (address, callback) => {
  sessionApiClient
    .post("/accounts/v2/suggest_email_replacement", {
      data: {
        attributes: {
          email: address,
        },
      },
    })
    .then(({ data }) => {
      return callback(data.attributes.suggested_replacement)
    })
}

const trackEmailSuggestionClick = (original_address, suggested_address) => {
  sessionApiClient.post("/accounts/v2/track_email_replacement_selected_count", {
    data: {
      attributes: {
        origin: "ccw_form",
        original_address,
        suggested_address,
      },
    },
  })
}

const NameBanner = ({ person, info, onClick, linkText }) => (
  <div className="my-4">
    <h2 className="h4 mb-1">{info}</h2>
    <div className="d-f ai-c jc-sb action-drawer">
      <div className="d-f">
        <div className="mr-2">
          <Avatar
            src={`${person.data.attributes.avatar_url}?g=200x200`}
            alt={` Profile image of ${person.data.attributes.name}`}
            size={6}
          />
        </div>
        <div className="d-f fd-c">
          <div>{person.data.attributes.name}</div>
          <div className="fs-13 c-tint2">
            {person.data.attributes.email_address}
          </div>
        </div>
      </div>
      {onClick && linkText && <a onClick={onClick}>{linkText}</a>}
    </div>
  </div>
)

const Thanks = ({
  attachments,
  verificationRequired,
  sendSubmissionNotificationToSubmitter,
  submissionConfirmation,
  onClick,
}) => {
  const {
    person_name: personName,
    person_email: personEmail,
  } = submissionConfirmation.basicInformation

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <div>
        {!verificationRequired && (
          <div className="alert success-alert">
            <div className="d-f p-r mb-1">
              <BigGreenCheck />
              <h1 className="h2 pl-5">
                Thanks! Your answers have been submitted.
              </h1>
            </div>
            <p className="pl-5 mb-4p">
              Your submission details are below
              {sendSubmissionNotificationToSubmitter
                ? `, and a copy has been emailed to you at ${personEmail}`
                : ""}
              .
            </p>
          </div>
        )}

        {verificationRequired && (
          <div>
            <div className="alert warning-alert p-3">
              <div className="d-f p-r mb-2 ai-c">
                <BigGreenCheck style={{ height: 24, width: 24 }} />
                <p className="h2 fw-400 pl-5 mb-0">
                  Your form has been submitted.
                </p>
              </div>
              {sendSubmissionNotificationToSubmitter && (
                <p className="pl-5 fs-3" style={{ marginTop: "-16px" }}>
                  A copy has been emailed to you at {personEmail}.
                </p>
              )}
              <div className="d-f p-r ai-c">
                <span className="icon c-citrine mr-1" style={{ fontSize: 24 }}>
                  <Icon symbol="general#exclamation-circle" aria-hidden />
                </span>
                <h1 className="h2 pl-1">
                  Next step: check your email to verify your updates.
                </h1>
              </div>
              <p className="pl-5 mb-4p fs-3">
                Your changes won’t take effect until you click on the “Verify”
                button in the email we send. Your submission details are below.
              </p>
            </div>
          </div>
        )}
      </div>
      <div>
        <div
          className="mb-2 pb-1"
          style={{
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: "#f3f3f3",
          }}
        >
          <div className="d-f fd-c my-1">
            <div>{personName}</div>
            <div>{personEmail}</div>
          </div>
        </div>
        {!!submissionConfirmation.groupedSubmissionValues.length && (
          <div
            className="mb-2 pb-1 "
            style={{
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "#f3f3f3",
            }}
          >
            <h2 className="h3">Form answers</h2>
            {submissionConfirmation.groupedSubmissionValues.map(group => {
              const fileField = attachments.find(
                att =>
                  parseInt(att.form_field_id, 10) === parseInt(group.id, 10)
              )
              const isTextField =
                group.field_type === "text" ||
                group.field_type === "note" ||
                group.custom_field_data_type === "text"

              return (
                <div key={`group_${group.id}`} className="mt-1 mb-2">
                  <div className="d-f jc-sb">
                    <div className="c-tint2">{group.label}</div>
                  </div>
                  {group.values.map(val => {
                    if (fileField) {
                      const previews = fileField.files.map(f => {
                        return {
                          url: window.URL.createObjectURL(f),
                          name: f.name,
                          extension: f.name.split(".").pop(),
                        }
                      })

                      return previews.map(f => (
                        <div key={f.url} className="my-1">
                          <a href={f.url} target="_blank" className="d-f ai-c">
                            <div
                              className="mr-1 lh-1 d-f ai-c jc-c c-tint4 o-h br-4p"
                              style={{
                                width: 28,
                                height: 28,
                                flex: "0 0 28px",
                                border: "1px solid",
                              }}
                            >
                              {["jpg", "jpeg", "gif", "png"].includes(
                                f.extension.toLowerCase()
                              ) ? (
                                <img
                                  width={28}
                                  height={28}
                                  style={{ objectFit: "cover" }}
                                  src={f.url}
                                />
                              ) : (
                                <div className="fs-4 c-tint3 mt-2p">
                                  <Icon symbol="general#outlined-generic-file" />
                                </div>
                              )}
                            </div>
                            <span className="overflow-text">{f.name}</span>
                            <span className="ml-1 fs-4 mt-4p">
                              <Icon symbol="general#new-window" />
                            </span>
                          </a>
                        </div>
                      ))
                    } else if (isTextField) {
                      return (
                        <div key={`${group.id}_${val}`}>
                          <Nl2Br>{val}</Nl2Br>
                        </div>
                      )
                    } else if (val) {
                      return <div key={`${group.id}_${val}`}>{val}</div>
                    } else {
                      return <br />
                    }
                  })}
                </div>
              )
            })}
          </div>
        )}
      </div>
      <div className="my-4">
        <a onClick={onClick}>Submit another response</a>
      </div>
    </>
  )
}

const Header = ({ name, description, children }) => {
  return (
    <header>
      <h1 className="h1">{name}</h1>
      {children}
      {description && (
        <div
          className="fs-3 c-tint0"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(description).replace(/\n/g, "<br />"),
          }}
        ></div>
      )}
    </header>
  )
}

function BigGreenCheck({ ...props }) {
  return (
    <svg
      className="ism-checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 54 54"
      {...props}
    >
      <circle
        className="ism-checkmark__circle"
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      <path
        className="ism-checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  )
}

const triggerIsCheckboxes = (values, conditionTriggerId) =>
  typeof values[conditionTriggerId] === "object" &&
  Object.keys(values[conditionTriggerId]).includes("selected")

const otherOptionIsChecked = conditionOptionTrigger =>
  parseInt(conditionOptionTrigger.id) === OTHER_OPTION_ID

const optionIsSelectedInCheckboxes = ({
  conditionOptionTrigger,
  conditionTrigger,
  conditionTriggerId,
  included,
  values,
}) => {
  if (otherOptionIsChecked(conditionOptionTrigger)) {
    // it's the "other" option so check to see if "other" is selected
    return Object.keys(values[conditionTriggerId]).includes("other")
  } else {
    // it's checkboxes so check the selected array
    const fieldType = fieldIsCustomField(conditionTrigger)
      ? "FieldOption"
      : "FormFieldOption"
    return (
      values[conditionTriggerId].selected.includes(conditionOptionTrigger.id) &&
      included.find(
        f => f.id === conditionOptionTrigger.id && f.type === fieldType
      )
    )
  }
}

const optionIsSelectedInDropdown = (
  conditionTriggerId,
  conditionOptionTrigger,
  conditionOptionValueTrigger
) =>
  conditionTriggerId === conditionOptionValueTrigger ||
  (conditionOptionTrigger && conditionTriggerId === conditionOptionTrigger.id)

const trimmedValues = values =>
  pickBy(values, value => !(typeof value === "string" && !value.trim()))

const valueIsCheckboxes = value =>
  value.selected && Array.isArray(value.selected)

const valueIsAddressField = value => value.street && value.street2

const valueIsEmptyAddress = (fieldId, value, fields) => {
  const field = fields.find(field => field.id === fieldId)
  if (field?.attributes.field_type !== "address") return false

  return !value.city && !value.street && !value.state && !value.zip
}

const valueIsBlankDropdownOtherOption = value =>
  Object.keys(value).includes("other") && value.other.trim() === ""

const valueIsAttachment = value => value === "attachments"

const includedParamForCheckboxes = (value, key) => {
  let selected = value.selected.map((_k, i) => ({
    type: "FormSubmissionValue",
    attributes: {
      form_field_id: key,
      value: value.selected[i],
    },
  }))

  if (value["other"]) {
    selected = selected.concat([
      {
        type: "FormSubmissionValue",
        attributes: {
          form_field_id: key,
          value: { other: value["other"] },
        },
      },
    ])
  }
  return selected
}

const uploadFileAttachment = async attachment => {
  const response = await Promise.all(
    attachment.files.map(file => sessionApiClient.uploadFile(file))
  )

  const files = response.map(({ data }) => ({
    fileID: data[0].id,
    fileName: data[0].attributes.name,
  }))

  return {
    form_field_id: attachment.form_field_id,
    files,
  }
}

const buildIncludedParam = (values, uploadedAttachments, fields) =>
  flatten(
    compact(
      Object.keys(values).map(key => {
        if (valueIsCheckboxes(values[key])) {
          return includedParamForCheckboxes(values[key], key)
        } else if (valueIsAttachment(values[key])) {
          const attachment = uploadedAttachments.find(
            a => parseInt(a.form_field_id, 10) === parseInt(key, 10)
          )

          return {
            type: "FormSubmissionValue",
            attributes: {
              form_field_id: key,
              value: attachment.files,
            },
          }
        } else {
          if (valueIsAddressField(values[key])) {
            // concatenate address line 1 & 2
            values[key].street = [values[key].street, values[key].street2].join(
              "\n"
            )
            delete values[key].street2
          }

          if (valueIsEmptyAddress(key, values[key], fields)) {
            values[key] = {}
          }

          // If "other" is blank, return null which will be compacted from the final array
          if (valueIsBlankDropdownOtherOption(values[key])) return null

          return {
            type: "FormSubmissionValue",
            attributes: {
              form_field_id: key,
              value: values[key],
            },
          }
        }
      })
    )
  )

const convertObjectValuesToString = obj =>
  Object.keys(obj).reduce((final, name) => {
    if (obj[name]) {
      final[name] = obj[name].join(", ")
    }
    return final
  }, {})

const extractFieldErrors = formSubmissionErrors =>
  formSubmissionErrors &&
  formSubmissionErrors.meta.associated_resources.reduce((errors, e) => {
    const id = e.relationships.form_field.data.id || null
    if (id) errors[id] = convertObjectValuesToString(e.errors)
    return errors
  }, {})

const extractAuthErrors = errors =>
  errors
    .filter(e => {
      if (!e.source) return false
      return [
        "person.first_name",
        "person.last_name",
        "person.emails.address",
        "person",
      ].includes(e.source.parameter)
    })
    .reduce((errors, error) => {
      const parameter = error.source.parameter
        .replace("person.", "")
        .replace("emails", "email")
        .replace(/\.|_/, " ")

      errors[parameter] = { [parameter]: error.detail }
      return errors
    }, {})
